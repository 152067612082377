<template>
  <article>
    <Hero heading="Transforming the way you charge">
      <template slot="image">
        <picture>
          <img src="@/assets/images/PlugAndCharge/hero.jpg" alt="Plugging in an electric vehicle charging connector" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock
      heading="Plug&Charge - "
      headingLineTwo="It really is that simple"
      headlineDisplayClass="headline3 extra-wide"
      :maxWidth="1000"
    >
      <p>
        For eligible vehicles, this cutting-edge technology simplifies the charging process. After a quick enrollment,
        owners with Electrify America
        <router-link class="link" :to="{ name: 'premium-offers-en' }">charging benefits</router-link> and an eligible
        electric vehicle (EV) can enjoy the convenience of seamless charging, so all you do is drive up, plug in, and
        let Plug&Charge do the rest.
      </p>
    </TwoColumnContentBlock>

    <section class="just-plug-and-charge bg-gray">
      <div class="l-three-col tricard-container">
        <Card heading="No tapping screens" headlineLevel="h3" isSmall headlineDisplayClass="headline2">
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/x.png" alt="An X." />
          </template>
        </Card>
        <Card heading="No swiping credit cards" headlineLevel="h3" isSmall headlineDisplayClass="headline2">
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/x.png" alt="An X." />
          </template>
        </Card>
        <Card heading="Just plug in and charge" headlineLevel="h3" isSmall headlineDisplayClass="headline2">
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/check.png" alt="A checkmark." />
          </template>
        </Card>
      </div>
    </section>

    <section class="l-one">
      <div class="l-one__col">
        <h2 class="headline3">How it Works</h2>
        <p>
          After a quick enrollment, when you plug your EV into an Electrify America charger, Plug&Charge will
          automatically identify your account information and initiate the session.
        </p>
        <br />
        <br />
        <h2 class="headline3">Plug&Charge Eligible Vehicles</h2>
        <p>
          Confirm that your vehicle is eligible from the list below
          <reference-link :num="1">
            Contact your vehicle manufacturer for eligibility and activation instructions. </reference-link
          >. While all Electrify America chargers are Plug&Charge capable, not all vehicles are.
        </p>
        <div class="tip">
          <img loading="lazy" src="@/assets/images/GettingStarted/icon-star.png" alt="" />
          <p>You may find specific activation instructions within your vehicle manufacturer's mobile app.</p>
        </div>
        <accordion>
          <accordion-item title="Audi">
            <ul>
              <li>e-tron (2023)</li>
              <li>e-tron Sportback (2023)</li>
              <li>e-tron S (2023)</li>
              <li>Q8 e-tron (2024)</li>
              <li>SQ8 e-tron (2024)</li>
              <li>SQ8 Sportback e-tron (2024)</li>
            </ul>
          </accordion-item>
          <accordion-item title="BMW">
            <ul>
              <li>
                iX (2024-2025)<reference-link :num="2">
                  iX Model year 2024 vehicles produced on or after July 1, 2023 are Plug and Charge ready. If your
                  vehicle model was produced on or after March 1, 2023 and before July 1, 2023, please contact your BMW
                  Center to get your BMW Plug and Charge ready. Learn more at
                  <a target="_blank" href="https://www.bmwusa.com/modals/plug-and-charge.html"
                    >https://www.bmwusa.com/modals/plug-and-charge.html</a
                  >
                </reference-link>
              </li>
              <li>
                i4 (2024-2025)<reference-link :num="3">
                  i4 Model year 2024 vehicles produced before July 1, 2023 are not eligible. If your vehicle model was
                  produced on or after July 1, 2023, please contact your BMW Center to get your BMW Plug and Charge
                  ready. Learn more at
                  <a target="_blank" href="https://www.bmwusa.com/modals/plug-and-charge.html"
                    >https://www.bmwusa.com/modals/plug-and-charge.html</a
                  >
                </reference-link>
              </li>
              <li>i5 (2024-2025)</li>
              <li>i7 (2023-2025)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Ford">
            <ul>
              <li>Mustang Mach-E (all model years)</li>
              <li>F-150 Lightning (all model years)</li>
              <li>E-Transit (2023-2024)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Lucid">
            <ul>
              <li>Air (all model years)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Mercedes-Benz">
            <ul>
              <li>EQS Sedan (2022-2024)</li>
              <li>EQS SUV (2022-2024)</li>
              <li>EQS SUV Maybach (2023-2024)</li>
              <li>EQE Sedan (2023-2024)</li>
              <li>EQE SUV (2023-2024)</li>
              <li>EQB (2024)</li>
              <li>S 580e (2024)</li>
              <li>eSprinter (2024)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Porsche">
            <ul>
              <li>
                Taycan (2021-2025)<reference-link :num="4">
                  The 2021 Porsche Taycan supports Plug&Charge if the vehicle does not have the optional 19.2 kW onboard
                  charger (PR KB4)
                </reference-link>
              </li>
              <li>Taycan Cross Turismo (2023-2024)</li>
              <li>Macan Electric (2024-2025)</li>
            </ul>
          </accordion-item>
          <accordion-item class="bottom-border" title="Volkswagen">
            <ul>
              <li style="padding-top: 10px">ID.4 (2023-2024)</li>
            </ul>
            <br />
            <h5>Activating Plug&Charge in the Electrify America mobile app</h5>
            <ol class="custom-counter">
              <li>
                Open the Electrify America Mobile app, log in, and select <strong>"Account"</strong> at the bottom of
                your screen.
              </li>
              <li>
                Go to <strong>"Plans"</strong> in the menu and select your Premium Offer
                <reference-link :num="5">
                  Steps for activation within the Electrify America App. Must be enrolled in Premium Offer first.
                </reference-link>
                .
              </li>
              <li>
                Scroll down and select
                <strong>"Plug&Charge Activate Now."</strong>
              </li>
              <li>
                Follow the instructions in the app and confirm
                <strong>"Activate Plug&Charge."</strong>
              </li>
              <li>
                Select <strong>"OK"</strong> to activate Plug&Charge. Once activation is complete, you will see the
                <strong>"Enrolled"</strong> status next to your Premium Offer in the Electrify America mobile app.
              </li>
            </ol>
          </accordion-item>
        </accordion>
        <h5 class="l-one l-one__col learn-more">
          <router-link class="learn-more-text" :to="{ name: 'premium-offers-en' }">
            Learn more about our premium offers<arrow class="arrow"></arrow
          ></router-link>
        </h5>
      </div>
    </section>

    <section class="l-one bg-gray">
      <div class="l-one__col">
        <h2 class="headline3">How to use Plug&Charge</h2>
        <p>
          Once activated, our Plug&Charge technology is ready to use at any Electrify America charger. All you need to
          do is follow the steps below.
        </p>
        <ol class="custom-counter">
          <li>
            Drive to any Electrify America DC fast charging station.
            <router-link class="link" :to="{ name: 'locate-charger-en' }">Find the nearest charger.</router-link>
          </li>
          <li><strong>Plug in. </strong>Plug the charging connector into your electric vehicle.</li>
          <li><strong>Check the charge. </strong> Check the charger screen to ensure your charge has begun.</li>
          <li><strong>Relax. </strong> Now that your vehicle is charging, monitor your progress in the app.</li>
        </ol>
      </div>
    </section>
  </article>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion.vue';
import AccordionItem from '@/components/Accordion/AccordionItem.vue';
import Arrow from '@/assets/images/Global/arrow.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'getting-started',
  components: { Accordion, AccordionItem, Arrow, Card, Hero, ReferenceLink, TwoColumnContentBlock },
  metaInfo: {
    title: `Plug&Charge | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `For eligible vehicles, Electrify America's Plug&Charge technology simplifies the charging process. Learn how it works here.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/plug-and-charge/' }],
  },
};
</script>

<style lang="scss" scoped>
.just-plug-and-charge {
  padding-bottom: 40px;
  h2 {
    margin-left: 20px;
    margin-bottom: 60px;
  }
  ::v-deep .card {
    padding-top: 20px;
    padding-bottom: 10px;

    max-width: 250px;
    text-align: center;

    .card__headline {
      color: #030a4f;
    }
  }
  .l-three-col {
    justify-content: space-between;
  }
}
.tricard-container {
  max-width: 600px;
}
.bg-gray {
  background: $c-secondary-background;
}
.l-one {
  padding-top: 80px;
  padding-bottom: 80px;
}

.bottom-border {
  border-bottom: 1px solid $c-border-blue !important;
}

.tip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  img {
    width: 25px;
    height: 25px;
    order: 1;
    margin: 0;
  }
  p {
    margin: 0;
    margin-left: 15px;
    order: 2;
  }
}

.learn-more {
  padding-top: 20px;
  padding-bottom: 20px;
  .learn-more-text {
    font-weight: 700;
  }
}

.custom-counter li {
  list-style: none;
  counter-increment: step-counter;
  position: relative;
  margin: 20px 0;
  padding-left: 40px;
  min-height: 30px;
}

.custom-counter li:before {
  content: counter(step-counter);
  position: absolute;
  left: 0;

  color: white;
  background: #0a124a;
  display: inline-block;
  text-align: center;
  margin: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
